<template>
  <div class="c_task_details_pop">
    <van-popup v-model="show" :style="{width:'80%',height:'60%'}" :safe-area-inset-bottom="true" overlay-class="c-task-details-pop-overlay" class="c-task-details-pop-container">
      <div class="c-task-details-pop-close" @click="onCancel">
       <i class="iconfont icon-guanbi"></i> 
      </div>
      <div class="c-task-details-pop-head">
        <p class="c-task-details-pop-head-title">要求说明</p>    
      </div>
      <p class="c-task-details-pop-text">{{form.text}}</p>
    </van-popup>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        show: false,
        form: {
          text: ''
        }
      }
    },
    methods: {
      onShow(text) {
        this.form.text = text
        this.show = true
      },
      onCancel() {
        this.show = false
      }
    }
  }
</script>

<style lang="scss">
.c_task_details_pop {
  .c-task-details-pop-overlay {
    background-color: rgba(0,0,0,.4);
  }
  .c-task-details-pop-container {
    background: $white;
    border-radius: 8px;
    .c-task-details-pop-close {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 5px;
      .iconfont {
        font-size: 20px;
        color: #828282;
      }
    }
    .c-task-details-pop-head {
      padding-top: 16px;
      text-align: center;
      font-size: 16px;
      color: $main_color;
      padding-bottom: 24px;
    }
    .c-task-details-pop-text {
      text-align: justify;
      word-break: break-all;
      padding: 0 30px;
      color: #000000;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

</style>