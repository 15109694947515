<template>
  <div class="c_task_details">
    <van-popup v-model="show" overlay-class="c-task-details-overlay" :safe-area-inset-bottom="true" position="right" :style="{width: '100%', height: '100%' }" class="c-task-details-container">
      <head-back :isRouterBack="false" @emitHeadBack="emitHeadBack">
        <template v-slot:returnText>
          <span>返回</span>
        </template>
        <template>
          <span>{{title}}</span>
        </template>

        <template v-slot:returnRight>
          <span @click="apiSaveOrderHandel" v-if="seeStatus == 1">临时保存</span>
        </template>
      </head-back>

      <div class="c-task-details-content-box">
        <div class="c-task-details-content">
          <!-- 订单详情的上面那几个部分 纯描述的 -->
          <task-details-top :formData="formData"/>

          <!-- 订单详情的底部那几个部分 有操作的-->
          <task-details-bottom :formData="formData" :seeStatus="seeStatus" :form="formData.userOrder"/>

          <!-- 提交按钮 -->
          <div class="c-task-details-submit" v-if="seeStatus == 1">
            <operation-button :buttonObj="buttonObjSecond" @clickItem="emitClickItem"/>
          </div>
        </div>
      </div>  
    </van-popup>
    
    <!-- 任务要求的弹窗 -->
    <task-details-pop ref="TaskDetailsPop"/>
    
    
     <!-- 主宝贝浏览弹窗确认 -->
    <task-main-liu-lan-pop ref="TaskMainLiuLanPop" @emitConfirmManLiuLan="emitConfirmManLiuLan"/>
  </div>
</template>

<script>
  import HeadBack from '@/components/HeadBack.vue'
  import TaskDetailsPop from './TaskDetailsPop.vue'
  import TaskDetailsTop from './TaskDetailsTop.vue'
  import TaskDetailsBottom from './TaskDetailsBottom.vue'
  import OperationButton from '@/components/OperationButton.vue'
  import TaskMainLiuLanPop from '@/components/TaskMainLiuLanPop.vue'
  import { apiSaveOrder, apiConfirmStep } from '@/utils/api.js'
  export default {
    components: { HeadBack, TaskDetailsPop, TaskDetailsTop, TaskDetailsBottom, OperationButton, TaskMainLiuLanPop },
    data() {
      return {
        seeStatus: 1, //1 操作任务  2查看详情
        buttonObjSecond: {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '确认提交',
          event: 'sureSubmitClick'
        },
        show: false,
        title: '任务详情',
        formData: {
          actual_price: "50.00",
          ben_price: "501.00",
          buy_num: 10,
          buy_price: "4.50",
          close_time: 1608393600,
          create_time: 1608368978,
          credit_pay: 0,
          detail_keyword: "增值增值",
          entrance: "",
          entrance_pic: [],
          entrance_remark: "",
          explain: "",
          explain_img: [],
          goods: [],
          huobi: [],
          id: 54,
          img: [],
          is_free: 1,
          keyword: "增值增值",
          liulan: {},
          main_liulan: [],
          pay_type: "禁用花呗/信用卡/淘金币/白条",
          platform: {},
          refund_price: "0.00",
          screenshot: "0",
          screenshot_arrival: '0',
          search_price: "10.00",
          search_remark: "增值增值",
          search_sort: "综合排序",
          status: 30,
          task_time: 1608374378,
          task_type: {},
          title: "增值增值",
          tkl: "",
          type: 0,
          url: "增值增值",
          userShop: {},
          xdsj: {},
          userOrder: {
            id: 145, //订单id
            shop_name: "", //店铺名
            shop_enter_keyword_url: "", //进店关键词链接
            enter_shop_img: "",  //进店截图
            goods_img: "", //主宝贝截图
            order_detail_keyword: "",  //详情页关键词
            pay_img: "", //付款截图
            pay_order_number: "",  //支付订单编号
            real_pay_price: "", //实际付款金额
            huobi_url: [], //货比列表
            fbb_url: [], //副宝贝列表
          }
        }
      }
    },
    methods: {
      emitClickItem(event) {
        console.log(event);
        if(!event) return
        this[event]()
      },
      emitHeadBack() {
        this.show = false
      },
      // status  1 操作任务 2查看详情
      onShow(obj, status = 1) {
        console.log('status', status)
        this.seeStatus = status
        if(obj.explain) this.$refs.TaskDetailsPop.onShow(obj.explain) 
        console.log(obj)
        this.formData = obj
        this.show = true
      },
      // 临时保存数据
      async apiSaveOrderHandel() {
        console.log('临时保存')
        const { success, data } = await apiSaveOrder(this.formData.userOrder)
        if(!success) return
        this.$toast(data.msg)
        console.log(data)
      },
      // 确认主宝贝浏览触发
      emitConfirmManLiuLan() {
        this.apiConfirmStepHandel()
      },

      // 点击确认提交
      sureSubmitClick() {
        if(this.formData.userOrder.shop_name.trim() != this.formData.userShop.title.trim()) return this.$toast('请输入正确的店铺名')
        if(this.formData.entrance != '淘口令' && this.formData.entrance != '其他') {
          if(!this.formData.userOrder.shop_enter_keyword_url)  return this.$toast('请输入进店关键词链接')
          if(this.formData.userOrder.shop_enter_keyword_url.indexOf(this.formData.keyword) == -1) return this.$toast('请输入正确的进店关键词链接')
        }
        if(this.formData.detail_keyword.trim() != this.formData.userOrder.order_detail_keyword.trim())  return this.$toast('请输入正确的详情页关键词')
        if(this.formData.screenshot == 1) {
          if(!this.formData.userOrder.enter_shop_img) return this.$toast('请上传进店截图')
          if(!this.formData.userOrder.goods_img) return this.$toast('请上传主宝贝截图')
        }
        if(this.formData.userOrder.fbb_url.length > 0) {
          if(this.formData.userOrder.fbb_url.some(item => item.name == '')) return this.$toast('请填写副宝贝链接')
        }
        if(this.formData.userOrder.huobi_url.length > 0) {
          if(this.formData.userOrder.huobi_url.some(item => item.name == '')) return this.$toast('请填写货比链接')
        }
        // 如果需要主宝贝浏览
        if(this.formData.main_liulan.length) {
          this.$refs.TaskMainLiuLanPop.onShow(true, this.formData.main_liulan)
        }else {
          this.apiConfirmStepHandel()
        }
      },
      // 确认提交数据
      async apiConfirmStepHandel() {
        console.log('确认提交')
        const { success, data } = await apiConfirmStep(this.formData.userOrder)
        if(!success) return
        this.$toast(data.msg)
        this.show = false
        this.$emit('emitSaveSuccess')
      }
    }
  }
</script>

<style lang="scss">
.c_task_details {
  .c-task-details-overlay {
    background: #ffffff;
  }
  .c-task-details-container {
    background: #F1F1F1;
    display: flex;
    flex-direction: column;
    .c-task-details-content-box {
      flex: 1;
      overflow: auto;
      .c-task-details-content {
        height: 100%;
        overflow: auto;
      }
    }
  }
  .c-task-details-submit {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
</style>