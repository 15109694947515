<template>
  <div class="c_s_task_details_bottom">
    <div class="s-task-details-bottom-section">

    <!-- 第一步 -->
      <h3 class="s-task-details-bottom-section-title">第一步：货比浏览 验证店铺</h3>
      <!-- 提示 -->
      <div class="s-task-details-bottom-tips" v-if="formData.keyword">
        请确认使用<span>{{formData.userAccount.account}}</span>账号登录
        <span>{{formData.platform.id | filtersApp }}</span> 应用。打开客户端后手动输入搜索关键词"
        <span class="s-task-details-bottom-tips-keyword">{{formData.keyword}}</span>",关键词不可自行修改。
        按要求筛选搜索条件，请勿随意增加搜索条件，找到目标商品前可浏览至少2个同类商品，至少浏览1分钟，验证店铺名称，复制并上传分享链接。
      </div>
      <div class="s-task-details-bottom-tips" v-if="formData.entrance == '淘口令'">
        请复制淘口令<span>{{formData.tkl}}</span>使用<span>{{formData.userAccount.account}}</span>账号登录
        <span>{{formData.platform.id | filtersApp }}</span> 应用。打开客户端后进入店铺，验证店铺名称。
      </div>
      <div class="s-task-details-bottom-tips" v-if="formData.entrance == '其他'">
        请复制进店链接<span>{{formData.entrance_store}}</span>使用<span>{{formData.userAccount.account}}</span>账号登录
        <span>{{formData.platform.id | filtersApp }}</span> 应用。打开客户端后进入店铺，验证店铺名称。
      </div>
      <div class="s-task-details-bottom-see-btn" v-if="formData.keyword">
        <operation-button @clickItem="emitClickItem" :buttonObj="buttonObj"/>
      </div>

      <div class="s-task-details-bottom-see-btn" v-if="formData.entrance == '淘口令'">
        <operation-button @clickItem="emitClickItem" :buttonObj="buttonObjTkl"/>
      </div>
      
      <div class="s-task-details-bottom-see-btn" v-if="formData.entrance == '其他'">
        <operation-button @clickItem="emitClickItem" :buttonObj="buttonObjOther"/>
      </div>
      <!-- 输入 -->
      <div class="s-task-details-bottom-input">
        <!-- 进店关键词链接 -->
        <div class="s-task-details-bottom-input-item" v-if="formData.keyword">
          <van-field v-model="form.shop_enter_keyword_url" :readonly="computedSeeStatus" label="进店关键词链接:" placeholder="请输入进店关键词链接(长按粘贴)" />
          <i class="iconfont icon-dui1" v-show="iconFontShowSecond('shop_enter_keyword_url', formData.keyword) === 1" ></i>
          <i class="iconfont icon-cuo"  v-show="iconFontShowSecond('shop_enter_keyword_url', formData.keyword) === 0" ></i>
        </div>
        <!-- 店铺名 -->
        <div class="s-task-details-bottom-input-item">
          <van-field v-model="form.shop_name" :readonly="computedSeeStatus" label="店铺名:" placeholder="请输入店铺名(长按粘贴)" />
          <i class="iconfont icon-dui1" v-show="iconFontShow('shop_name', formData.userShop.title) === 1"></i>
          <i class="iconfont icon-cuo"  v-show="iconFontShow('shop_name', formData.userShop.title) === 0"></i>
        </div>
        <!-- 副宝贝链接 -->
        <div class="s-task-details-bottom-input-item" v-for="(item,index) in form.fbb_url" :key="'fbb' + index">
          <van-field :readonly="computedSeeStatus" v-model="item.name" :label="'副宝贝链接'+ (index+1) + ':'" :placeholder="'请输入副宝贝链接'+ (index+1) +'(长按粘贴)'" />
           <i class="iconfont icon-dui1" v-show="item.name"></i>
        </div>
        <!-- 货比链接 -->
        <div class="s-task-details-bottom-input-item" v-for="(item2,index2) in form.huobi_url" :key="'huobi' + index2">
          <van-field :readonly="computedSeeStatus" v-model="item2.name" :label="'货比链接' + (index2+1) + ':'" :placeholder="'请输入货比链接'+ (index2+1) + '(长按粘贴)'" />
           <i class="iconfont icon-dui1" v-show="item2.name"></i>
        </div>
      </div>
    </div>

    <!-- 第二步 -->
    <div class="s-task-details-bottom-section">
      <h3 class="s-task-details-bottom-section-title">第二步：浏览店铺 、验证关键词</h3>
      <!-- 提示 -->
      <div class="s-task-details-bottom-tips">
          根据主图、价格、名称等找到目标商品，点击进入店铺。
          回到目标商品，至上向下至少浏览3分钟，在目标商品的
          详情中找到以下问题的答案。如需购买附加商品，步骤同
          上一步至少从上而下浏览目标商品，在附近商品详情中找
          到相关问题答案                   
      </div>

      <!-- 输入 -->
      <div class="s-task-details-bottom-input s-task-details-bottom-setion-two-input">
        <div class="s-task-details-bottom-input-item s-task-details-bottom-setion-two-input-item">
          <span>请在目标商品详情中找到以下答案</span> 答案提示: <span class="s-task-details-bottom-tips-keyword">{{formData.detail_keyword | filtersDetailKeyWord }}</span>({{formData.detail_keyword.length}}个字)
        </div>
          <div class="s-task-details-bottom-input-item">
          <van-field v-model="form.order_detail_keyword" :readonly="computedSeeStatus" label="详情页关键词:" placeholder="请输入详情页关键词" />
          <i class="iconfont icon-dui1" v-show="iconFontShow('order_detail_keyword', formData.detail_keyword) === 1"></i>
          <i class="iconfont icon-cuo"  v-show="iconFontShow('order_detail_keyword', formData.detail_keyword) === 0"></i>
        </div>
      </div>

    </div>

    <!-- 第三步 -->
    <div class="s-task-details-bottom-section">
      <!-- <h3 class="s-task-details-bottom-section-title">第三步：聊天下单并提交付款信息</h3> -->
    
      <!-- 提示 -->
      <!-- <div class="s-task-details-bottom-tips s-task-details-bottom-setion-third-tips">
        确认好件数、颜色尺寸下单付款前核对好购买账号以及留言确认无误后付款。下单后填写实际付款金额与正确的订单编号。
        <div class="s-task-details-bottom-tips-top">
          <span>应垫付金额参考: <span class="g-fsize18 g-font-bold">{{ formData.ben_price }} 元</span> (可能有误差，请填写实际付款金额，实际付款金额不能大于应垫付金额100元)</span>
          <p class="s-task-details-bottom-tips-top-title">订单编号可在订单详情中查询</p>
        </div>                                      
      </div> -->

      <!-- 输入 -->
      <!-- <div class="s-task-details-bottom-input s-task-details-bottom-setion-third-input">
        <div class="s-task-details-bottom-input-item">
          <van-field v-model="form.real_pay_price" :readonly="computedSeeStatus" label="实际付款金额:" placeholder="请输入实际付款金额" oninput="value=value.replace(/[^0-9.]/g,'')" />
        </div>
         <div class="s-task-details-bottom-input-item">
          <van-field v-model="form.pay_order_number" :readonly="computedSeeStatus" label="支付订单号:" placeholder="请输入支付订单号(长按粘贴)" />
        </div>
      </div> -->

      <!-- 上传  screenshot 0不截图 1截图 -->
      <div class="s-task-details-bottom-upload g-flex-column" v-show="formData.screenshot == 1">
        <p class="s-task-details-bottom-upload-title">上传截图:</p>
        <div class="s-task-details-bottom-upload-list g-flex-align-center">
          <div class="s-task-details-bottom-upload-list-item g-flex-column g-flex-align-center g-flex-1">
            <task-details-bottom-upload :seeStatus="seeStatus" :imgUrl.sync="form.enter_shop_img" />
            <span class="s-task-details-bottom-upload-list-item-title">进店截图</span>
          </div>
          <div class="s-task-details-bottom-upload-list-item g-flex-column g-flex-align-center g-flex-1">
            <task-details-bottom-upload :seeStatus="seeStatus" :imgUrl.sync="form.goods_img" />
            <span class="s-task-details-bottom-upload-list-item-title">主宝贝截图</span>
          </div>
          <!-- <div class="s-task-details-bottom-upload-list-item g-flex-column g-flex-align-center g-flex-1">
            <task-details-bottom-upload :seeStatus="seeStatus" :imgUrl.sync="form.pay_img" />
            <span class="s-task-details-bottom-upload-list-item-title">付款截图</span>
          </div> -->
        </div>
      </div>
      <div class="s-task-details-bottom-upload g-flex-column s-task-details-bottom-upload-second" v-if="formData.screenshot_arrival == 1 && form.receive_goods_img && form.comment_img">
        <div class="s-task-details-bottom-upload-list g-flex-align-center" >
          <div class="s-task-details-bottom-upload-list-item g-flex-column g-flex-align-center g-flex-1">
            <task-details-bottom-upload :seeStatus="seeStatus" :imgUrl.sync="form.receive_goods_img" />
            <span class="s-task-details-bottom-upload-list-item-title">确认截图</span>
          </div>
          <div class="s-task-details-bottom-upload-list-item g-flex-column g-flex-align-center g-flex-1">
            <task-details-bottom-upload :seeStatus="seeStatus" :imgUrl.sync="form.comment_img" />
            <span class="s-task-details-bottom-upload-list-item-title">评价截图</span>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
  import { ImagePreview } from 'vant';
  import OperationButton from '@/components/OperationButton.vue'
  import TaskDetailsBottomUpload from '@/components/TaskDetailsBottomUpload.vue'
  export default {
    props: {
      // 1 操作任务 2查看详情
      seeStatus: {
        type: Number,
        default() {
          return 1
        }
      },
      formData: {
        type: Object,
        default() {
          return {
            actual_price: "",
            ben_price: "",
            buy_num: 0,
            buy_price: "",
            close_time: 0,
            create_time: 0,
            credit_pay: 0,
            detail_keyword: "",
            entrance: "",
            entrance_pic: [],
            entrance_remark: "",
            explain: "",
            explain_img: [],
            goods: [],
            huobi: [],
            id: 0,
            img: [],
            is_free: 0,
            keyword: "",
            liulan: {},
            main_liulan: [],
            pay_type: "",
            platform: {},
            refund_price: "0",
            screenshot: "0",
            screenshot_arrival: '0',
            search_price: "0",
            search_remark: "",
            search_sort: "",
            status: 0,
            task_time: 0,
            task_type: {},
            title: "",
            tkl: "",
            type: 0,
            url: "",
            userShop: {},
            xdsj: {},
            userAccount: {},
            userOrder:{}
          }
        }
      },
      form: {
        type: Object,
        default() {
          return {
            id: '', //订单id
            shop_enter_keyword_url:'', //进店关键词链接
            order_detail_keyword: '', //详情页关键词
            real_pay_price: '', //实际付款金额
            pay_order_number: '', //支付订单编号
            enter_shop_img: '', //进店截图
            goods_img: '', //主宝贝截图
            pay_img: '', //付款截图
            shop_name: '', //店铺名
            name: '',
            huobi_url: [], //货比列表
            fbb_url: [], //副宝贝列表
            receive_goods_img: '', //收货截图
            comment_img: '' //评价截图
          }
        }
      }
    },
    components: { OperationButton, TaskDetailsBottomUpload },
    data() {
      return {
        list: [{ name: ''}, { name: ''}, { name: ''}],
        keywordImgUrl: '/image/shop_enter_keyword_url.png',
        buttonObj: {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '查看关键词链接示例图',
          event: 'seeKeyWordClick'
        },
        buttonObjTkl: {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '复制淘口令',
          event: 'copyTkl'
        },
        buttonObjOther: {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '复制进店链接',
          event: 'copyOther'
        }
      }
    },
    filters: {
      filtersStoreName(dataStr) {
        let str = ''
        for(let i = 0;i<dataStr.length; i++) {
          if(i % 2 === 0) str+= dataStr.substr(i, 1)
          else if(i % 2 === 1) str = str + '*'
        }
        return str
      },
      filtersApp(id) {
        if(id == 1) return '手机淘宝'
        if(id == 2) return '手机京东'
        if(id == 3) return '手机拼多多'
      },
      filtersDetailKeyWord(detailkeyword) {
        let str = ''
        for(let i = 0;i<detailkeyword.length;i++) {
          if(i % 2 === 0) str+= detailkeyword.substr(i, 1)
          else if(i % 2 === 1) str = str + '*'
        }
        return str
      }
    },
    methods: {
      // 查看关键字链接
      seeKeyWordClick() {
        ImagePreview([this.keywordImgUrl]);
      },
      // 复制淘口令
      copyTkl() {
        this.copyClick(this.formData.tkl)
      },
      // 复制进店链接
      copyOther() {
        this.copyClick(this.formData.entrance_store)
      },
      // 复制
      copyClick(message) {
        this.$copyText(message).then(() => {
          this.$toast({
            type: 'success',
            message: '复制成功'
          });
        },  () => {
          this.$toast('复制失败,请重试');
        })
      },

      emitClickItem(event) {
        if(!event) return
        this[event]()
      },
    },
    computed: {
      computedSeeStatus() {
        if(this.seeStatus == 1) return false
        return true
      },
      iconFontShow(){
        // key 输入的key值 val正确的值
        return (key, val) => {
          // console.log('key', key)
          // console.log('val', this.form[key])
          //为空
          if(!this.form[key]) return -1 
          if(this.form[key].trim() === val.trim()) {
          // 相等
            return 1
          }else {
          // 不等
            return 0
          }
        }
      },
       iconFontShowSecond() {
        return (key, val) => {
          //为空
          if(!this.form[key]) return -1 
          if(this.form[key].indexOf(val) != -1) {
          // 相等
            return 1
          }else {
          // 不等
            return 0
          }
        }
       }
    }
  }
</script>

<style lang="scss">
.c_s_task_details_bottom {
  padding-bottom: 20px;
  .s-task-details-bottom-section {
    margin-top: 20px;
  }
  .s-task-details-bottom-section-title {
    color: $white;
    font-size: 16px;
    background: $main_color;
    padding: 18px 20px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .s-task-details-bottom-tips {
    padding: 18px;
    font-size: 14px;
    color: #0C0C0C;
    line-height: 22px;
    text-align: justify;
    .s-task-details-bottom-tips-top {
      margin-top: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #DCDCDC;
      .s-task-details-bottom-tips-top-title {
        padding-top: 10px;
        font-weight: bold;
      }
    }
    span {
      color: $main_color;
      &.s-task-details-bottom-tips-keyword {
        font-size: 18px;
        font-weight: bold;
      }
    }
    &.s-task-details-bottom-setion-third-tips {
      background: $white;
    }
  }

  .s-task-details-bottom-input {
    margin-top: 14px;
    padding-left: 8px;
    background: $white;
    .s-task-details-bottom-input-item {
      border-bottom: 1px solid #DCDCDC;
      padding-right: 18px;
      background: $white;
      position: relative;
      &:nth-last-of-type(1) {
        border-bottom: none;
      }
      .van-cell {
         display: flex;
        align-items: center;
        padding: 10px;
        &::after {
          border-bottom: none;
        }
        .van-cell__title {
          flex: 1;
          color: #323233;
        }
        .van-cell__value {
          flex: 2;
        }
      }
      .iconfont {
        position: absolute;
        right: 0px;
        top: 50%;
        padding: 0 4px;
        transform: translateY(-50%);
        color: $main_color;
        z-index: 10;
        font-size: 26px;
      }
    }
    &.s-task-details-bottom-setion-two-input {
      margin-top: 0px;
      font-size: 14px;
      .s-task-details-bottom-setion-two-input-item {
        padding-top: 16px;
        padding-bottom: 16px;
        line-height: 22px;
        span {
          color: $main_color;
          font-size: 14px;
          &.s-task-details-bottom-tips-keyword {
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
    }
    &.s-task-details-bottom-setion-third-input {
      margin-top: 0;
    }
  }

  .s-task-details-bottom-upload {
    padding: 0 16px 0px 8px;
    &.s-task-details-bottom-upload-second {
      margin-top: 20px;
    }
    .s-task-details-bottom-upload-title {
      padding: 10px 0;
      font-size: 14px;
      color: #323233;
      font-weight: bold;
    }
    .s-task-details-bottom-upload-list {
      .s-task-details-bottom-upload-list-item {
        .s-task-details-bottom-upload-list-item-title {
          color: $grey;
          font-size: 14px;
          padding-top: 10px;
        }
      }
    }
  }

  .s-task-details-bottom-submit {
    margin-top: 20px;
  }
}
</style>