<template>
  <div class="c_s_task_details_top">
    <div class="c-s-task-details-top-refuse" v-if="formData.remark">
      <h3 class="c-s-task-details-top-info-item-title">驳回原因</h3>
      <p class="c-s-task-details-top-refuse-text">{{formData.remark}}</p>
    </div>
    <!-- 商品图片部分 -->
    <div class="c-s-task-details-top-card">
      <div class="c-s-task-details-top-card-head g-flex-align-center">
        <i class="iconfont" :class="formData.platform.icon"></i>
        <span class="c-s-task-details-top-card-head-store">{{ formData.userShop.title | filtersStoreName}}</span>
      </div>
      <div class="c-s-task-details-top-card-bottom g-flex-align-center">
        <img class="c-s-task-details-top-card-bottom-img" :src="formData.img[0]" alt="" @click="imgPreviewSecondClick(formData.img[0])"/>
        <div class="c-s-task-details-top-card-bottom-right">
          <div class="c-s-task-details-top-card-bottom-right-top g-flex-align-center">
            <span class="c-s-task-details-top-card-bottom-right-top-name">{{formData.title | filtersStoreName}}</span>
            <p class="c-s-task-details-top-card-bottom-right-top-price"><span>¥</span>{{formData.search_price}}</p>
          </div>
          <!-- <div class="c-s-task-details-top-card-bottom-right-bottom">
            <span class="c-s-task-details-top-card-bottom-right-bottom-num">×{{formData.buy_num}}</span>
          </div> -->

          <div class="c-s-task-details-top-card-bottom-right-btn g-flex-justify-end">
            <p>{{formData.task_type.title}}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="c-s-task-details-top-info">
      <h3 class="c-s-task-details-top-info-item-title">试用商品信息</h3>
      <ul class="c-s-task-details-top-info-list">
        <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">平台类型:</span>
          <p class="c-s-task-details-top-info-list-item-val g-font-red g-fsize16 g-font-bold">{{formData.platform.title}}</p>
        </li>
        <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">商品图片:</span>
          <div class="c-s-task-details-top-info-list-item-val">
            <img class="c-s-task-details-top-card-bottom-img" v-for="(item,index) in formData.img" :key="index" :src="item" alt="" @click="imgPreviewSecondClick(item)"/>
          </div>
        </li>
        <li class="c-s-task-details-top-info-list-item" v-show="formData.entrance">
          <span class="c-s-task-details-top-info-list-item-label">商品入口:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.entrance }}</p>
        </li>
        <li class="c-s-task-details-top-info-list-item" v-show="formData.entrance_remark">
          <span class="c-s-task-details-top-info-list-item-label">入口描述:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.entrance_remark }}</p>
        </li>
        <li class="c-s-task-details-top-info-list-item" v-show="formData.entrance_pic.length">
          <span class="c-s-task-details-top-info-list-item-label">步骤截图:</span>
          <div class="c-s-task-details-top-info-list-item-val">
            <img v-for="(item,index) in formData.entrance_pic" :key="index" class="c-s-task-details-top-card-bottom-img" :src="item" alt="" @click="imgPreviewClick(item)"/>
          </div>
        </li>
        <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">店铺名:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.userShop.title | filtersStoreName }}</p>
        </li>
        <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">旺旺号:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.userShop.title | filtersStoreName }}</p>
        </li>
         <li class="c-s-task-details-top-info-list-item" v-show="formData.search_remark">
          <span class="c-s-task-details-top-info-list-item-label">搜索备注:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.search_remark }}</p>
        </li>
        <!-- 三个只展示一个 可以复制 -->
        <li class="c-s-task-details-top-info-list-item" v-if="formData.keyword">
          <span class="c-s-task-details-top-info-list-item-label">搜索关键字:</span>
          <p class="c-s-task-details-top-info-list-item-val g-fsize16 g-font-red g-font-bold">{{formData.keyword}}</p>
          <!-- <van-button type="primary" size="mini" @click="copyClick(formData.keyword)">复制</van-button> -->
        </li>
        <li class="c-s-task-details-top-info-list-item" v-if="formData.tkl">
          <span class="c-s-task-details-top-info-list-item-label">淘口令:</span>
          <p class="c-s-task-details-top-info-list-item-val g-font-red g-font-bold">{{formData.tkl}}</p>
          <van-button type="primary" size="mini" @click="copyClick(formData.tkl)">复制</van-button>
        </li>
        <li class="c-s-task-details-top-info-list-item" v-if="formData.entrance_store">
          <span class="c-s-task-details-top-info-list-item-label">进店链接:</span>
          <p class="c-s-task-details-top-info-list-item-val g-font-red g-font-bold">{{formData.entrance_store}}</p>
          <van-button type="primary" size="mini" @click="copyClick(formData.entrance_store)">复制</van-button>
        </li>
        <!-- End -->
        <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">搜索排序方式:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.search_sort }}</p>
        </li>
        <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">搜索展示价:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.search_price }}元</p>
        </li>

        <!-- <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">宝贝下单价:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.actual_price }}元</p>
        </li> -->
        
        <!-- <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">宝贝拍下件数:</span>
          <p class="c-s-task-details-top-info-list-item-val g-font-red g-font-bold"> {{formData.buy_num}}件</p>
        </li> -->
       
        <div class="c-s-task-details-top-info-list-item-addgoods" v-if="formData.goods.length">
          <h3>附加商品信息</h3>
          <div v-for="(item, index) in formData.goods" :key="index">
            <li class="c-s-task-details-top-info-list-item">
              <span class="c-s-task-details-top-info-list-item-label">附加商品{{index + 1}}图片:</span>
              <div class="c-s-task-details-top-info-list-item-val">
                <img class="c-s-task-details-top-card-bottom-img" :src="item.img" alt="" @click="imgPreviewClick(item.img)"/>
              </div>
            </li>
            <li class="c-s-task-details-top-info-list-item">
              <span class="c-s-task-details-top-info-list-item-label">附加商品{{index + 1}}下单价:</span>
              <p class="c-s-task-details-top-info-list-item-val">{{ item.price }}元</p>
            </li>
            <li class="c-s-task-details-top-info-list-item">
              <span class="c-s-task-details-top-info-list-item-label">附加商品{{index + 1}}拍下件数:</span>
              <p class="c-s-task-details-top-info-list-item-val  g-font-red g-font-bold">{{ item.num }}件</p>
            </li>
          </div>
        </div>

        <!-- <div class="c-s-task-details-top-info-list-item-addgoods g-padtop10">
          <h3>下单总价:</h3>
          <li class="c-s-task-details-top-info-list-item">
            <span class="c-s-task-details-top-info-list-item-label">宝贝下单总价:</span>
            <p class="c-s-task-details-top-info-list-item-val g-fsize20 g-font-red g-font-bold"> {{ formData.ben_price }}元</p>
          </li>
        </div> -->

    
      </ul>      
    </div>
    <!-- 试用活动要求 -->
    <div class="c-s-task-details-top-info">
      <h3 class="c-s-task-details-top-info-item-title">试用活动要求</h3>
      <ul class="c-s-task-details-top-info-list">
        <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">浏览副宝贝:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.liulan.title }}</p>
        </li>
        <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">货比商品:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.huobi.title }}</p>
        </li>
        <!-- <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">付款方式:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.pay_type }}</p>
        </li> -->
        <!-- v-show="formData.main_liulan.length" -->
        <li class="c-s-task-details-top-info-list-item" >
          <span class="c-s-task-details-top-info-list-item-label">主宝贝浏览:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{ formData.main_liulan | filtersMainLiulan }}</p>
        </li>
        <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">操作中截图:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{formData.screenshot | filtersJietu }}</p>
        </li>
        <!-- <li class="c-s-task-details-top-info-list-item">
          <span class="c-s-task-details-top-info-list-item-label">确认收货截图:</span>
          <p class="c-s-task-details-top-info-list-item-val">{{formData.screenshot_arrival | filtersJietu }}</p>
        </li> -->
        <li class="c-s-task-details-top-info-list-item c-s-task-details-top-info-list-item-req">
          <span class="c-s-task-details-top-info-list-item-label">特殊要求:</span>
          <p class="c-s-task-details-top-info-list-item-val g-font-red g-font-bold">{{formData.explain}}</p>
        </li>
        <li class="c-s-task-details-top-info-list-item c-s-task-details-top-info-list-item-req" v-show="formData.explain_img.length">
          <span class="c-s-task-details-top-info-list-item-label">特殊说明示例图:</span>
          <img class="c-s-task-details-top-card-bottom-img"  v-for="(item,index) in formData.explain_img" :key="index" :src="item" alt="" @click="imgPreviewClick(item)"/>
        </li>
      </ul>      
    </div>
  </div>
</template>

<script>
  import { ImagePreview } from 'vant';
  export default {
  props: {
    formData: {
      type: Object,
      default() {
        return {
          actual_price: "",
          ben_price: "",
          buy_num: 0,
          buy_price: "",
          close_time: 0,
          create_time: 0,
          credit_pay: 0,
          detail_keyword: "",
          entrance: "",
          entrance_pic: [],
          entrance_remark: "",
          explain: "",
          explain_img: [],
          goods: [],
          huobi: [],
          id: 0,
          img: [],
          is_free: 0,
          keyword: "",
          liulan: {},
          main_liulan: [],
          pay_type: "",
          platform: {},
          refund_price: "0",
          screenshot: "0",
          screenshot_arrival: '0',
          search_price: "0",
          search_remark: "",
          search_sort: "",
          status: 0,
          task_time: 0,
          task_type: {},
          title: "",
          tkl: "",
          type: 0,
          url: "",
          userShop: {},
          xdsj: {},
        }
      }
    }
  },
  methods: {
    // 预览 水印
    imgPreviewSecondClick(url) {
      ImagePreview([url + '?x-oss-process=style/mi'])
    },
    // 预览
    imgPreviewClick(url) {
      ImagePreview([url])
    },
    // 复制
    copyClick(message) {
      this.$copyText(message).then(() => {
        this.$toast({
          type: 'success',
          message: '复制成功'
        });
      },  () => {
        this.$toast('复制失败,请重试');
      })
    },
  },
  filters: {
    filtersStoreName(dataStr) {
        let str = ''
        for(let i = 0;i<dataStr.length; i++) {
          if(i % 2 === 0) str+= dataStr.substr(i, 1)
          else if(i % 2 === 1) str = str + '*'
        }
        return str
      },
      filtersMainLiulan(mainliulan) {
        if(mainliulan.length) return mainliulan.map(item => item.title).join(',')
        return '不浏览'
      },
      filtersJietu(val) {
        if(val == 1) return '截图'
        return '不截图'
      }
    },
  }
</script>

<style lang="scss">
.c_s_task_details_top {
  .c-s-task-details-top-card-bottom-img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    object-fit: cover;
  }
  .c-s-task-details-top-info-item-title {
    color: #0C0C0C;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
  }
  .c-s-task-details-top-refuse {
    margin-top: 12px;
    padding: 12px 20px;
    background: $white;
    .c-s-task-details-top-refuse-text {
      font-size: 14px;
      color: #6E6E6E;
      letter-spacing: 1px;
      line-height: 22px;
    }
  }
  .c-s-task-details-top-card {
    margin-top: 12px;
    padding: 12px 20px;
    background: $white;
    .c-s-task-details-top-card-head {
      font-size: 16px;
      color: #000000;
      .iconfont {
        font-size: 20px;
        color: $main_color;
        padding-right: 10px;
      }
      .c-s-task-details-top-card-head-store {
        flex: 1;
      }
    }
    .c-s-task-details-top-card-bottom {
      padding-top: 16px;
      .c-s-task-details-top-card-bottom-right {
        padding-left: 16px;
        flex: 1;
        .c-s-task-details-top-card-bottom-right-top {
          padding-top: 6px;
          font-size: 16px;
          color: #000000;
          .c-s-task-details-top-card-bottom-right-top-name {
            flex: 1;
          }
          .c-s-task-details-top-card-bottom-right-top-price {
            span {
              font-size: 12px;
            }
          }
        }

        .c-s-task-details-top-card-bottom-right-bottom {
          padding-top: 14px;
          font-size: 16px;
          color: #A0A0A0;
          text-align: right;
        }

        .c-s-task-details-top-card-bottom-right-btn {
          margin-top: 10px;
          p {
            line-height: 1;
            padding: 6px 20px;
            border-radius: 14px;
            font-size: 15px;
            color: $main_color;
            border: 1px solid #FA436A;
            font-weight: bold;
          }
        }
      }
    }
  }


  .c-s-task-details-top-info {
    padding: 14px 20px;
    background: $white;
    margin-top: 12px;
    .c-s-task-details-top-info-list {
      padding-bottom: 4px;
      .c-s-task-details-top-info-list-item-addgoods {
        h3 {
          font-size: 14px;
          font-weight: bold;
          padding-top: 20px;
        }
      }
      .c-s-task-details-top-info-list-item {
        padding-top: 20px;
        display: flex;
        align-items: center;
        color: #0C0C0C;
        font-size: 15px;
        &.c-s-task-details-top-info-list-item-req {
          align-items: flex-start;
        }
        .c-s-task-details-top-info-list-item-label {
          flex: 2;
        }
        .c-s-task-details-top-info-list-item-val {
          color: #656363;
          font-size: 14px;
          flex: 3;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-end;
          text-align: justify;
          letter-spacing: 1px;
          line-height: 22px;
        }
      }
    }
  }
}
</style>